import React from 'react';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import './Trust.css';
import './TrustTwo.css';

export const TrustTwo = () => {

  const swiperParams = {
    slidesPerView: 1, // Default slides per view (for larger screens)
    spaceBetween: 20,
    navigation: true, // Enable navigation buttons
    speed: 1200,

    breakpoints: {
      1024: {
        slidesPerView: 2,
      },
      1440: {
        slidesPerView: 3,
      },
    },
  };


  return (
    <div className='trustSec'>
      <div className="trustDesc">
        <h2>Your Trust, Our Priority</h2>
        <h3>We Ensure Customer Satisfaction, Privacy, and Data Protection</h3>
      </div>
      <Swiper className="trustList" modules={[Navigation, A11y]}
          // spaceBetween={3}
          {...swiperParams}>
      
        <SwiperSlide className="tListItem">
          <div className="tIcon">
            <DoneOutlineIcon />
            <h4>Experience and Expertise</h4>
          </div>
          <div className="tContent">
            
            <p>With over 10 years of serving New York residents, Instantly Insured has established a solid reputation for providing reliable insurance advice and exceptional customer service. We have an in-depth understanding of the local insurance landscape, offering you unmatched insights and solutions.</p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="tListItem">
          <div className="tIcon">
            <DoneOutlineIcon />
            <h4>Top-Rated Carriers</h4>
          </div>
          <div className="tContent">
            
            <p>We collaborate with a network of renowned insurance carriers, ensuring that you receive access to a wide range of reliable and top-rated insurance products. Our partnerships empower us to find the perfect coverage options that fit your lifestyle and budget.</p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="tListItem">
          <div className="tIcon">
            <DoneOutlineIcon />
            <h4>Client Testimonials</h4>
          </div>
          <div className="tContent">
            
            <p>Don't just take our word for it. Our satisfied clients speak for themselves. Check out our glowing testimonials to discover the transformative impact of Instantly Insured's guidance and support.</p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="tListItem">
          <div className="tIcon">
            <DoneOutlineIcon />
            <h4>Responsive Support</h4>
          </div>
          <div className="tContent">
            
            <p>At Instantly Insured, we value your time and concerns. Our responsive team of agents is here to listen to your questions, address your queries promptly, and provide tailored insurance solutions that best suit your needs.</p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="tListItem">
          <div className="tIcon">
            <DoneOutlineIcon />
            <h4>Privacy and Security</h4>
          </div>
          <div className="tContent">
            
            <p>Your privacy matters to us. We adhere to strict data protection protocols, ensuring that your personal information remains secure throughout your interactions with Instantly Insured.</p>
          </div>
        </SwiperSlide>

        <SwiperSlide className="tListItem">
          <div className="tIcon">
            <DoneOutlineIcon />
            <h4>Local Involvement</h4>
          </div>
          <div className="tContent">
            
            <p>As passionate members of the New York community, we believe in giving back. Instantly Insured actively participates in local initiatives and charitable events, aiming to make a positive impact on the lives of those we serve.</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}