import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from 'react-router-dom';
import './FooterTwo.css';

export const FooterTwo = () => {
  return (
    <div className='footer'>
      <div className="footerContent">
        <div className="fcDesc">
          <h3>INSTANTLY INSURED</h3>
          <p>At Instantly Insured, we believe in transparent communication and addressing your concerns. If you have any other questions or need further information, feel free to contact us. Expect the Unexpected, and trust in our commitment to your insurance needs.</p>
        </div>
        <div className="fcLinks">
          <div className="resourcesLinks">
            <h4>RESOURCES</h4>
            {/* <Link className='fLink' to="/">Home</Link> */}
            {/* <Link className='fLink' to="/about_us">About Us</Link> */}
            <Link className='fLink' to="/insurance_services">Insurance Services</Link>
            <Link className='fLink' to="/insurance_carriers">Insurance Carriers</Link>
            <Link className='fLink' to="/contact_us">Contact Us</Link>
            <Link className='fLink' to="/FAQs">FAQs</Link>
            <Link className='fLink' to="/search">Search</Link>
          </div>
          <div className="resourcesLinks con">
            <h4>CONTACT US</h4>
            <p><LocalPhoneIcon /><a className='fsiLink' href="tel:(516) 200-4649">(516) 200-4649</a></p>
            <p><EmailIcon />hello@instantlyinsured.com</p>
            <p><HomeIcon />4 New Hyde Park Rd - 4, Franklin Square, NY 11010</p>
            <p><AccessTimeIcon />9 AM to 6 PM - 7 days a week</p>
          </div>
          <div className="socialMedia">
            <a href="https://www.facebook.com/profile.php?id=61550318829504" target="_blank" rel="noopener noreferrer" className='fsiLink'><FacebookIcon className='fsIcon' /></a>
            {/* <TwitterIcon className='fsIcon' /> */}
            <InstagramIcon className='fsIcon' />
          </div>
        </div>
      </div>
      {/* <div className="footerForm">
        <form action="">
          <input type="text" placeholder='Name' />
          <input type="email" placeholder='Email' />
          <textarea name="message" id="" cols="12" rows="10" placeholder='Your message'></textarea>
          <button className='quote-btn heroBtn'>Submit</button>
        </form>
      </div> */}

      <div className="copyWrite">
        <div className="copyRightText">
          <p>&#169; 2023 Instantly Insurance. All Rights Reserved</p>
        </div>
        <div className="policyLinks">
          <Link to="/sitemap" className='plLink'><p>Sitemap</p></Link>
          <Link to="/terms-of-service" className='plLink'><p>Terms of Service</p></Link>
          <Link to="/privacy-policy" className='plLink'><p>Privacy Policy</p></Link>
        </div>
      </div>
    </div>
  )
}
