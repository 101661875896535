import React, { useEffect } from 'react';
import { Navbar } from '../../Components/Navbar/Navbar';
import { InsuranceHero } from '../../Components/InsuranceTypes/InsuranceHero/InsuranceHero';
import { InsuranceServices } from '../../Components/InsuranceServices/InsuranceServices';
import { FooterTwo } from '../../Components/Footer/FooterTwo';

export const InsuranceTypes = () => {

  useEffect(() => {
		document.title = 'Insurance Services -Instantly Insured';
	  }, []);

  return (
    <>
      <Navbar />
      <InsuranceHero currentPage= "Insurance Services"/>
      <InsuranceServices currentPage="Insurance Services"/>
      <FooterTwo />
    </>
  )
}
