import React from 'react';
import { Navbar } from '../../Components/Navbar/Navbar';
import { FooterTwo } from '../../Components/Footer/FooterTwo';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import './Thanks.css';
import { Link } from 'react-router-dom';

const Thanks = () => {
  return (
    <>
      <Navbar />
      <div className='thanks'>
        <div className="msgIcon">
          <MailOutlineIcon className='msg' />
        </div>

        <div className="thanksText">
          <h2>Thank you for submitting!</h2>
          <p>Your message has been sent!</p>
        </div>

        <div className="gohomeBtn">
          <Link to="/"><button className='quote-btn'>Go Home</button></Link>
        </div>
      </div>
      <FooterTwo />
    </>
  )
}

export default Thanks