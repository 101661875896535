import React, { useEffect, useRef } from 'react';
import HandshakeIcon from '@mui/icons-material/Handshake';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ArticleIcon from '@mui/icons-material/Article';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ListIcon from '@mui/icons-material/List';
import { motion, useAnimation, useInView } from 'framer-motion';
import './WhyChooseUs.css';

const initialVaariants = {
  hidden: {
    x: -100,
    opacity: 0
  },
  visible: {
    x: 0,
    opacity: 1
  }
}

const initialVaariantsTwo = {
  hidden: {
    x: 100,
    opacity: 0
  },
  visible: {
    x: 0,
    opacity: 1
  },

}

export const WhyChooseUs = ({ currentPage }) => {

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true} );
  const mainControls = useAnimation();

  useEffect(() => {
    if(isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls])

  return (
    <div className={`whyChooseUs ${currentPage === "Home" ? "homeStyle" : "getQuoteStyle"}`} ref={ref} style={{ overflow: "hidden"}}>
      <div className="whyUsTitle">
        { currentPage === "Home" && <h2>Why Choose Us</h2>}
        { currentPage === "GetQuote" && <h2>Why Choose Instantly Insured</h2>}
        { currentPage === "InsuranceCarriers" && <h2>How We Choose Our Partners</h2>}
      </div>
      <div className="wcuList">
        <div className="wcuListItem"
        variants={initialVaariants}
        initial="hidden"
        animate={mainControls}
        transition={{ delay: 0.3, duration: 1 }}
        >
          <div className="icon">
            <HandshakeIcon style={{ fontSize: 45 }} />
          </div>
          {currentPage === "Home"  && <h4>Customized Coverage</h4>}
          {currentPage === "Home"  && <p>We tailor our auto insurance policies to match your lifestyle and driving habits, ensuring that you get the coverage you need without paying for unnecessary extras.
          </p>}

          {currentPage === "GetQuote"  && <h4>Quick Response</h4>}
          {currentPage === "GetQuote"  && <p>Our dedicated agents will respond promptly to your quote request, ensuring you get the information you need without delays.</p>}

          {currentPage === "InsuranceCarriers"  && <h4>Financial Stability</h4>}
          {currentPage === "InsuranceCarriers"  && <p>We prioritize carriers with a strong financial foundation, ensuring they can honor claims and provide support during unforeseen events.</p>}
        </div>

        <div className="wcuListItem"
          variants={initialVaariants}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 0.3, duration: 1 }}
        >
          <div className="icon">
            <WorkspacePremiumIcon style={{ fontSize: 45 }}/>
          </div>
          {currentPage === "Home"  && <h4>Affordable Premiums</h4>}
          {currentPage === "Home"  && <p>We offer competitive and budget-friendly premium options, providing you with value for your money without compromising on coverage.
          </p>}

          {currentPage === "GetQuote"  && <h4>Customized Solutions</h4>}
          {currentPage === "GetQuote"  && <p>We take your unique needs into account, offering tailored coverage options that fit your lifestyle and budget.</p>}

          {currentPage === "InsuranceCarriers"  && <h4>Customer Service</h4>}
          {currentPage === "InsuranceCarriers"  && <p>Our chosen carriers have a reputation for exceptional customer service, which aligns with our commitment to putting clients first.</p>}
        </div>

        <div className="wcuListItem"
          variants={initialVaariants}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 0.3, duration: 1 }}
        >
          <div className="icon">
            <ArticleIcon style={{ fontSize: 45 }}/>
          </div>
          {currentPage === "Home"  && <h4>Quick Claims Processing</h4>}
          {currentPage === "Home"  && <p>In times of accidents or emergencies, we prioritize efficient claims processing to get you back on the road as soon as possible.</p>}

          {currentPage === "GetQuote"  && <h4>No Obligations</h4>}
          {currentPage === "GetQuote"  && <p>Requesting a quote is completely free, and there's no obligation to purchase a policy.</p>}

          {currentPage === "InsuranceCarriers"  && <h4>Coverage Options</h4>}
          {currentPage === "InsuranceCarriers"  && <p> We collaborate with carriers offering comprehensive coverage options that cater to various insurance needs.</p>}
        </div>

        <div className="wcuListItem"
          variants={initialVaariantsTwo}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 0.3, duration: 1 }}
        >
          <div className="icon">
            <SupportAgentIcon style={{ fontSize: 45 }}/>
          </div>
          {currentPage === "Home"  && <h4>24/7 Support</h4>}
          {currentPage === "Home"  && <p>Our dedicated team of agents is available round the clock to assist you with any queries, claims, or policy adjustments.</p>}

          {currentPage === "GetQuote"  && <h4>Expert Guidance</h4>}
          {currentPage === "GetQuote"  && <p>Our experienced agents are here to guide you through the process, providing valuable insights and answering any insurance-related questions.</p>}

          {currentPage === "InsuranceCarriers"  && <h4>Claim Processing Efficiency</h4>}
          {currentPage === "InsuranceCarriers"  && <p>Our partners are known for their quick and efficient claims processing, ensuring clients receive timely support when they need it the most.</p>}
        </div>

        <div className="wcuListItem"
          variants={initialVaariantsTwo}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 0.3, duration: 1 }}
        >
          <div className="icon">
            <TipsAndUpdatesIcon style={{ fontSize: 45 }}/>
          </div>
          {currentPage === "Home"  && <h4>Discount Opportunities</h4>}
          {currentPage === "Home"  && <p>We explore various discount opportunities to help you save on your auto insurance premium, rewarding safe driving records and other qualifying factors.</p>}

          {currentPage === "GetQuote"  && <h4>Transparent Information</h4>}
          {currentPage === "GetQuote"  && <p>You can trust us to provide clear and transparent information about your quote and coverage options.</p>}

          {currentPage === "InsuranceCarriers"  && <h4>Industry Reputation</h4>}
          {currentPage === "InsuranceCarriers"  && <p>We work with carriers that have earned a positive reputation within the insurance industry, signifying their commitment to excellence.</p>}
        </div>

        {currentPage === "Home"  && <div className="wcuListItem"
          variants={initialVaariantsTwo}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 0.3, duration: 1 }}
        >
          <div className="icon">
            <ListIcon style={{ fontSize: 45 }}/>
          </div>
          {currentPage === "Home"  && <h4>Bundle Options</h4>}
          {currentPage === "Home"  && <p>Consider bundling your auto insurance with other policies, such as home or medical insurance, to maximize savings and simplify your coverage management.</p>}
        </div>}
      </div>
    </div>
  )
}
