import React, { useEffect, useState } from 'react';
import { useForm } from '@formspree/react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import './Contact.css';

export const Contact = ({ currentPage }) => {

  const formClasses = `form ${currentPage === 'Home' ? '' : 'contactPageForm'}`;
  const formBgClasses = `contactContent ${currentPage === 'Home' ? '' : 'contactPageContent'}`;
  const formClass = ` ${currentPage === 'Home' ? '' : 'cPFrom'}`;
  const contactSectionClass = `contactSection ${currentPage === 'Home' ? '' : 'cpcs'}`;

  //Phone number formatiting
  const [phoneNumber, setPhoneNumber] = useState('');
  // const [submitted, setSubmitted] = useState(false);

  const handlePhoneNumberChange = (event) => {
    const input = event.target.value;
    const formattedInput = input.replace(/\D/g, ''); // Remove non-digit characters

    if (formattedInput.length <= 10) {
      // Limit to 10 digits for US phone numbers

      // Format the phone number as "123 456 7890"
      const formattedPhoneNumber = formattedInput.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');

      setPhoneNumber(formattedPhoneNumber);
    }
  };

  // const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [state, handleSubmit] = useForm('mnqkvdpg');

  useEffect(() => {
    if (state.succeeded) {
      window.location.href = '/thanks';
    }
  }, [state.succeeded]);

  return (
    <div className={contactSectionClass}>
      <div className="contactTitle">
        <h2>Contact</h2>
      </div>
      <div className={formBgClasses}>
        {currentPage === "Home" && <div className="contactDetails">
          <h3><LocalPhoneIcon />(516) 200-4649</h3>
          <h3><EmailIcon />hello@instantlyinsured.com</h3>
          <h3><HomeIcon />4 New Hyde Park Rd - 4, Franklin Square, NY 11010</h3>
          <h3><AccessTimeIcon />9 AM to 6 PM - 7 days a week</h3>
        </div>}
        <div className={formClasses}>
          <div className="formDesc">
            {currentPage !== "Home" && <h3> TOMORROW IS IMPORTANT, BUT LIFE IS HAPPENING NOW</h3>}
            {/* {currentPage !== "Home" && <h4>WE'D LOVE TO HEAR FROM YOU</h4>} */}

            <h3>Keep in touch. We'd love to hear from you </h3>
            <h4>If you prefer to send us a message, please complete
              the form below and we will get right back to you.</h4>

          </div>
          <form action="submit" className={formClass} onSubmit={handleSubmit}>
            {currentPage !== "Home" && <label htmlFor="" className='contactFormLabel'>Name <span className='red'> *</span></label>}
            <input className='cFContent' name="name" type="text" placeholder='Enter your name' required />
            {currentPage !== "Home" && <label htmlFor="" className='contactFormLabel'>Email<span className='red'> *</span></label>}
            <input className='cFContent' name="email" type="email" placeholder='Enter your email' required />
            {currentPage !== "Home" && (
              <>
                <label htmlFor="" className='contactFormLabel'>Phone Number<span className='red'> *</span></label>
                <input
                  className='cFContent'
                  name='phone'
                  type="text"
                  placeholder="Share the best contact number for our agents to reach you."
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  required
                />
                {phoneNumber.length < 10 && (
                  <span style={{ color: 'red' }}>Please enter a valid 10 digits phone number</span>
                )}
              </>
            )}
            {currentPage !== "Home" && <label htmlFor="" className='contactFormLabel'>Message</label>}
            <textarea className='cFContent' name="message" id="" cols="30" rows="10" placeholder='Your message...' required></textarea>
            <button className='quote-btn contactBtn' type='submit'>Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}
