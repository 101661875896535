import React, { useEffect, useRef } from 'react';
import { Navbar } from '../../Navbar/Navbar';
import { FooterTwo } from '../../Footer/FooterTwo';
import { motion, useAnimation, useInView } from 'framer-motion';
import './SingleInsuranceService.css';


const initialVaariants = {
  hidden: {
    x: -100,
    opacity: 0
  },
  visible: {
    transform: 'skew(27deg)',
    x: 0,
    opacity: 1,
  }
}

const descVaariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
  }
}

export const SingleInsuranceService = ({ sisImage, insuranceTitle, insuranceDesc, currentPage }) => {

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls])

  return (
    <>
      <Navbar />
      <div className='singleInsuranceService'>
        <div className="siSHero">

          <div className="siSHeroDesc">
            <h1 className="sishTtitle">{insuranceTitle}</h1>
            <h3>{insuranceDesc}</h3>
          </div>

          <div className="sishImg">
            <img src={sisImage} alt="Insurance Services" className='iSImg' />
          </div>
        </div>

        <div className="insTContent">
          {/* <div className="siSHeroDesc">
            <h3>{insuranceDesc}</h3>
          </div> */}

          <div className="ourAppraoch">
            <h2>Our Approach</h2>
            <h3>At Instantly Insured, we take a consultative approach to insurance. Our experienced agents take the time to understand your unique needs and risks. Based on this understanding, we recommend customized coverage solutions that align with your budget and preferences.</h3>
          </div>

          <div className="whyChooseInsurance" ref={ref}>
            <h2>Why Choose Instantly Insured</h2>

            {currentPage !== "life" && <div className="section" >
              <div className="heading services">
                <motion.h3
                  variants={initialVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.3, duration: 1 }}
                >Tailored Solutions</motion.h3>
              </div>
              <div className="content">
                <motion.p
                  variants={descVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.8, duration: 1.5 }}
                >We believe in offering personalized insurance solutions that suit your lifestyle, family dynamics, and business requirements.</motion.p>
              </div>
            </div>}

            {currentPage !== "life" && <div className="section" >
              <div className="heading services">
                <motion.h3
                  variants={initialVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.3, duration: 1 }}
                >Top-Rated Carriers</motion.h3>
              </div>
              <div className="content">
                <motion.p
                  variants={descVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.8, duration: 1.5 }}
                >We collaborate with reputable insurance carriers, ensuring you have access to reliable and financially stable coverage options.</motion.p>
              </div>
            </div>}

            {currentPage !== "life" && <div className="section" >
              <div className="heading services">
                <motion.h3
                  variants={initialVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.3, duration: 1 }}
                >Customer Advocacy</motion.h3>
              </div>
              <div className="content">
                <motion.p
                  variants={descVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.8, duration: 1.5 }}
                >As your dedicated insurance partner, we act as your advocate, providing guidance, support, and quick claims assistance.</motion.p>
              </div>
            </div>}

            {currentPage !== "life" && <div className="section" >
              <div className="heading services">
                <motion.h3
                  variants={initialVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.3, duration: 1 }}
                >Transparent and Honest</motion.h3>
              </div>
              <div className="content">
                <motion.p
                  variants={descVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.8, duration: 1.5 }}
                >We are committed to transparency and honesty in all our interactions. We explain policies, coverages, and premiums clearly so you can make informed decisions.</motion.p>
              </div>
            </div>}

            {currentPage !== "life" && <div className="section" >
              <div className="heading services">
                <motion.h3
                  variants={initialVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.3, duration: 1 }}
                >Local Expertise</motion.h3>
              </div>
              <div className="content">
                <motion.p
                  variants={descVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.8, duration: 1.5 }}
                >Our team's deep knowledge of the New York insurance landscape ensures you receive the most relevant and effective coverage for your specific needs.</motion.p>
              </div>
            </div>}

            {currentPage === "life" && <div className="section" >
              <div className="heading services">
                <motion.h3
                  variants={initialVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.3, duration: 1 }}
                >Customized Plans</motion.h3>
              </div>
              <div className="content">
                <motion.p
                  variants={descVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.8, duration: 1.5 }}
                >We design life insurance policies that align with your financial goals, whether it's providing for your family's future or leaving a legacy for generations to come.</motion.p>
              </div>
            </div>}

            {currentPage === "life" && <div className="section" >
              <div className="heading services">
                <motion.h3
                  variants={initialVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.3, duration: 1 }}
                >Flexible Coverage</motion.h3>
              </div>
              <div className="content">
                <motion.p
                  variants={descVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.8, duration: 1.5 }}
                >Our policies come with flexible coverage options, allowing you to choose the level of protection that suits your needs and budget.</motion.p>
              </div>
            </div>}

            {currentPage === "life" && <div className="section" >
              <div className="heading services">
                <motion.h3
                  variants={initialVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.3, duration: 1 }}
                >Financial Support</motion.h3>
              </div>
              <div className="content">
                <motion.p
                  variants={descVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.8, duration: 1.5 }}
                >In the event of your passing, your beneficiaries will receive a lump-sum payment that can help cover living expenses, debts, education costs, and more.</motion.p>
              </div>
            </div>}

            {currentPage === "life" && <div className="section" >
              <div className="heading services">
                <motion.h3
                  variants={initialVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.3, duration: 1 }}
                >Peace of Mind</motion.h3>
              </div>
              <div className="content">
                <motion.p
                  variants={descVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.8, duration: 1.5 }}
                >Life insurance provides peace of mind, knowing that your loved ones will have the support they need during challenging times.</motion.p>
              </div>
            </div>}

            {currentPage === "life" && <div className="section" >
              <div className="heading services">
                <motion.h3
                  variants={initialVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.3, duration: 1 }}
                >Affordable Premiums</motion.h3>
              </div>
              <div className="content">
                <motion.p
                  variants={descVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.8, duration: 1.5 }}
                >We offer competitive premium rates that ensure you get the coverage you need without straining your finances.</motion.p>
              </div>
            </div>}

            {currentPage === "life" && <div className="section" >
              <div className="heading services">
                <motion.h3
                  variants={initialVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.3, duration: 1 }}
                >Expert Guidance</motion.h3>
              </div>
              <div className="content">
                <motion.p
                  variants={descVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.8, duration: 1.5 }}
                >Our experienced agents are here to guide you through the process, explaining the different policy types and helping you make an informed decision.</motion.p>
              </div>
            </div>}

            {currentPage === "life" && <div className="section" >
              <div className="heading services">
                <motion.h3
                  variants={initialVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.3, duration: 1 }}
                >Tailored Solutions</motion.h3>
              </div>
              <div className="content">
                <motion.p
                  variants={descVaariants}
                  initial="hidden"
                  animate={mainControls}
                  transition={{ delay: 0.8, duration: 1.5 }}
                >Whether you're a young professional, a parent, or planning for retirement, our life insurance solutions can be tailored to your specific life stage and circumstances.</motion.p>
              </div>
            </div>}

            {currentPage === "life" && <div className='lifeInsurance'>
              <h3>With Instantly Insured's Life Insurance, you can provide your loved ones with the protection and stability they deserve. Expect the Unexpected, and secure your family's financial future with our reliable and compassionate service.</h3>
            </div>
            }

          </div>
        </div>
      </div>

      <FooterTwo />
    </>
  )
}
