import React from 'react';
import testimoanilaBg from '../../assets/testimonailbg.jpg';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Testimonial.css';


const testimonials = [
  {
    quote: "Instantly Insured made getting home insurance a breeze! Their quick and easy process gave me peace of mind knowing my home is protected.",
    person: "Sarah Thompson",
  },
  {
    quote: "I'm impressed with Instantly Insured's personalized approach. They tailored a home insurance plan that fits my needs perfectly. Great service!",
    person: "John Smith",
  },
  {
    quote: "Kudos to Instantly Insured for their excellent customer support. They patiently answered all my questions, helping me choose the right home insurance coverage.",
    person: "Emily Rodriguez",
  },
  {
    quote: "Instantly Insured not only saved me money but also provided comprehensive coverage. I recommend them to anyone looking for hassle-free home insurance.",
    person: "Michael Johnson",
  },
  // Add more testimonials as needed
];

export const Testimonial = () => {

  const swiperParams = {
    slidesPerView: 1, // Default slides per view (for larger screens)
    spaceBetween: 20,
    // navigation: true, 
    speed: 1200,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
  };

  return (
    <div className='testimonial'>
      <div className="testimonialDesc">
        <h2>Testimonials</h2>
        <h3>Words From Our Valued Clients</h3>
      </div>
      <div className="testimonail-contnet">

        <Swiper {...swiperParams} modules={[A11y, Autoplay]}>
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="testimonial-container">


                <div className="people-quotes">
                  <div className="quote-icon">
                    <FormatQuoteIcon />
                  </div>
                  <div className="person-detail">
                    <h4>{testimonial.person}</h4>
                  </div>
                  <div className="quote">
                    <p>{testimonial.quote}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="testimonialBg">
          <img src={testimoanilaBg} alt="testimonial" />
        </div>
      </div>
    </div>
  )
}
