import React, { useEffect } from 'react';
import { Navbar } from '../../Components/Navbar/Navbar';
// import contactBg from '../../assets/contactBg.jpg';
import { Contact } from '../../Components/Contact/Contact';
import { FooterTwo } from '../../Components/Footer/FooterTwo';
import './ContactPage.css';

export const ContactPage = () => {

  useEffect(() => {
		document.title = 'Contact Us -Instantly Insured';
	  }, []);

  return (
    <>
      <Navbar />
      {/* <div className="contactPHero">
        <img src={contactBg} alt="Contact" />
        <div className="chDesc">
          <h1 className="chdTitle">Tomorrow is important, but life is happening now.</h1>
          <h2 className="chdSubTitle">We’d love to hear from you</h2>
        </div>
      </div> */}
      <Contact />
      <FooterTwo />
    </>
  )
}
