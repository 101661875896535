import React, { useState } from 'react';
import { Navbar } from '../Navbar/Navbar';
import { FooterTwo } from '../Footer/FooterTwo';
import './Search.css';

const data = [
  { id: 1, title: 'Auto Insurance', description: 'Learn more about Auto Insurance here', path: '/insurance_services/auto' },
  // { id: 2, title: 'Mediacal Insurance', description: 'Learn more about Medical Insurance here', path: '/insurance_services/medical' },
  // { id: 3, title: 'Business Insurance', description: 'Learn more about Business Insurance here', path: '/insurance_services/business' },
  { id: 4, title: 'Home Insurance', description: 'Learn more about Home Insurance here', path: '/insurance_services/home' },
  { id: 5, title: 'Insurance Types', description: 'Learn more about Insurance here', path: '/insurance_services' },
];

export const Search = () => {

  const [searchResults, setSearchResults] = useState([]);
  const [searchPaths, setSearchPaths] = useState([]);
  const [query, setQuery] = useState(''); // Track the search query

  const handleSearch = newQuery => {
    setQuery(newQuery); // Update the search query
    if (newQuery === '') {
      setSearchResults([]);
      setSearchPaths([]);
      return;
    }

    const results = data.filter(item =>
      item.title.toLowerCase().includes(newQuery.toLowerCase())
    );

    setSearchResults(results);
    setSearchPaths(results.map(result => result.path));
  };


  return (
    <>
      <Navbar />
      <div className="searchComponent">
      <h1>Use the search bar to quickly find specific information on the website</h1>

        <div>
          <input
            type="text"
            placeholder="Search..."
            value={query} // Bind the input value to the query state
            onChange={e => handleSearch(e.target.value)}
          />
        </div>
        <div>
          {searchResults.map((result, index) => (
            <div key={result.id}>
              <h3><a href={searchPaths[index]}>{result.title}</a></h3>
              <p>{result.description}</p>
            </div>
          ))}
        </div>

      </div>
      <FooterTwo />
    </>
  )
}
