import React, { useEffect, useRef } from 'react';
import auto from '../../assets/1.jpg';
// import home from '../../assets/homeinsurance.jpg';
import home from '../../assets/2.jpg';
import medical from '../../assets/3.jpg';
import business from '../../assets/4.jpg';
import life from '../../assets/life.jpg';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useInView } from 'framer-motion';
// import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import './InsuranceServices.css';

const initialVaariants = {
  hidden: {
    y: 100,
    opacity: 0
  },
  visible: {
    y: 0,
    opacity: 1
  }
}

const imagelVaariants = {
  hidden: {
    y: -100,
    opacity: 0
  },
  visible: {
    y: 0,
    opacity: 1
  }
}

export const InsuranceServices = ({ currentPage, marginTop }) => {

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls])

  const componentStyles = {
    marginTop: marginTop || 0, // Default to 0 if marginTop is not provided
  };

  return (
    <div className='insuranceServices' style={componentStyles}>
      {currentPage === 'Home' && <h2 className="aboutTitle">Insurance Services</h2>}
      {currentPage === 'Home' && <h3>At Instantly Insured, we offer a comprehensive range of insurance services tailored to meet the diverse needs of New York residents. Our expert team is dedicated to providing you with the perfect coverage options, ensuring that you and your loved ones are protected against life's unpredictable events. Explore our array of insurance services designed to offer peace of mind and financial security:</h3>}
      <div className="insuranceTypesContainer" ref={ref}>

        <div className="insuranceType" >
          <motion.div className="insuranceDesc"
            variants={initialVaariants}
            initial="hidden"
            animate={mainControls}
            transition={{ delay: 0.4, duration: 1.3 }}
          >
            {currentPage === "Insurance Services" && <Link to="home" className='link'><h3>Home Insurance</h3></Link>}
            {currentPage === "Home" && <Link to="insurance_services/home" className='link'><h3>Home Insurance</h3></Link>}
            <p>Your home is your sanctuary, and our home insurance plans are tailored to shield your property and belongings from unforeseen disasters, such as fires, storms, and theft. We ensure that your most valuable investment is well-protected.</p>
            {currentPage === "Home" && <Link to="insurance_services/home" className='link'><p className="seeMore-btn">See More<KeyboardDoubleArrowRightIcon /></p></Link>}
            {currentPage === "Insurance Services" && <Link to="home" className='link'><p className="seeMore-btn">See More<KeyboardDoubleArrowRightIcon /></p></Link>}
          </motion.div>
          <motion.div className="insuranceTypeImage"
            variants={imagelVaariants}
            initial="hidden"
            animate={mainControls}
            transition={{ delay: 0.4, duration: 1.3 }}
          >
            <img src={home} alt="Home insurance" />
          </motion.div>
        </div>

        <div className="insuranceType">
          <motion.div className="insuranceDesc"
            variants={initialVaariants}
            initial="hidden"
            animate={mainControls}
            transition={{ delay: 0.3, duration: 1.2 }}

          >
            {currentPage === "Insurance Services" && <Link to="auto" className='link'><h3>Auto Insurance</h3></Link >}
            {currentPage === "Home" && <Link to="insurance_services/auto" className='link'><h3>Auto Insurance</h3></Link >}
            <p>Whether you own a car, motorcycle, or any other vehicle, our auto insurance policies are crafted to safeguard you from the risks of accidents, theft, and liabilities. Drive with confidence knowing that you're protected on the roads of New York.</p>
            {currentPage === "Home" && <Link to="insurance_services/auto" className='link'><p className="seeMore-btn">See More<KeyboardDoubleArrowRightIcon /></p></Link>}
            {currentPage === "Insurance Services" && <Link to="auto" className='link'><p className="seeMore-btn">See More<KeyboardDoubleArrowRightIcon /></p></Link>}
          </motion.div>
          <motion.div className="insuranceTypeImage"
            variants={imagelVaariants}
            initial="hidden"
            animate={mainControls}
            transition={{ delay: 0.3, duration: 1.2 }}
          >
            <img src={auto} alt="Auto insurance" />
          </motion.div>
        </div>

        {/* <div className="insuranceType">
          <motion.div className="insuranceDesc"
            variants={initialVaariants}
            initial="hidden"
            animate={mainControls}
            transition={{ delay: 0.4, duration: 1.4 }}
          >
            {currentPage === "Insurance Services" && <Link to="medical" className='link'><h3>Medical Insurance</h3></Link>}
            {currentPage === "Home" && <Link to="insurance_services/medical" className='link'><h3>Medical Insurance</h3></Link>}
            <p>Your health and well-being are paramount, and our medical insurance solutions are designed to provide you with access to quality healthcare. With comprehensive coverage options, you can focus on your health without worrying about medical expenses.</p>
            {currentPage === "Home" && <Link to="insurance_services/medical" className='link'><p className="seeMore-btn">See More<KeyboardDoubleArrowRightIcon /></p></Link>}
            {currentPage === "Insurance Services" && <Link to="medical" className='link'><p className="seeMore-btn">See More<KeyboardDoubleArrowRightIcon /></p></Link>}
          </motion.div>
          <motion.div className="insuranceTypeImage"
            variants={imagelVaariants}
            initial="hidden"
            animate={mainControls}
            transition={{ delay: 0.4, duration: 1.4 }}
          >
            <img src={medical} alt="" />
          </motion.div>
        </div> */}

        <div className="insuranceType">
          <motion.div className="insuranceDesc"
            variants={initialVaariants}
            initial="hidden"
            animate={mainControls}
            transition={{ delay: 0.5, duration: 1.5 }}
          >
            {currentPage === "Insurance Services" && <Link to="business" className='link'><h3>Business Insurance</h3></Link>}
            {currentPage === "Home" && <Link to="insurance_services/business" className='link'><h3>Business Insurance</h3></Link>}
            <p>As a business owner in New York, you face unique challenges. Our business insurance coverage safeguards your company against potential liabilities, property damage, and other risks, allowing you to focus on growth and success.</p>
            {currentPage === "Home" && <Link to="insurance_services/business" className='link'><p className="seeMore-btn">See More<KeyboardDoubleArrowRightIcon /></p></Link>}
            {currentPage === "Insurance Services" && <Link to="business" className='link'><p className="seeMore-btn">See More<KeyboardDoubleArrowRightIcon /></p></Link>}
          </motion.div>
          <motion.div className="insuranceTypeImage"
            variants={imagelVaariants}
            initial="hidden"
            animate={mainControls}
            transition={{ delay: 0.5, duration: 1.5 }}
          >
            <img src={business} alt="" />
          </motion.div>
        </div>

        <div className="insuranceType">
          <motion.div className="insuranceDesc"
            variants={initialVaariants}
            initial="hidden"
            animate={mainControls}
            transition={{ delay: 0.5, duration: 1.5 }}
          >
            {currentPage === "Insurance Services" && <Link to="life" className='link'><h3>Life Insurance</h3></Link>}
            {currentPage === "Home" && <Link to="insurance_services/life" className='link'><h3>Life Insurance</h3></Link>}
            <p>At Instantly Insured, we understand the importance of securing your family's future, no matter what lies ahead. Our Life Insurance service offers comprehensive coverage options designed to provide financial protection and peace of mind. Whether you're looking to safeguard your loved ones' well-being or leave a legacy, our tailored policies cater to your unique needs.</p>
            {currentPage === "Home" && <Link to="insurance_services/life" className='link'><p className="seeMore-btn">See More<KeyboardDoubleArrowRightIcon /></p></Link>}
            {currentPage === "Insurance Services" && <Link to="life" className='link'><p className="seeMore-btn">See More<KeyboardDoubleArrowRightIcon /></p></Link>}
          </motion.div>
          <motion.div className="insuranceTypeImage"
            variants={imagelVaariants}
            initial="hidden"
            animate={mainControls}
            transition={{ delay: 0.5, duration: 1.5 }}
          >
            <img src={life} alt="" />
          </motion.div>
        </div>

      </div>

      {/* {currentPage === 'Home' && <div className="highlightedInsurance">
        <div className='HighlightedBg'></div>
        <div className="highInsuContent">
          <h2>Auto Insurance</h2>
          <h4>Your vehicle is more than just a means of transportation; it represents your freedom and independence. At Instantly Insured, we understand the significance of your car and your safety on the road. That's why our auto insurance service is designed to cater to your specific needs and preferences.
          </h4>
          <Link to="/get_a_quote"><button className='quote-btn insurance'>Get a Quote</button></Link>
        </div>
      </div>} */}
    </div>
  )
}
