import React from 'react';
import './TermsOfUse.css';
import { Navbar } from '../../Components/Navbar/Navbar';
import { FooterTwo } from '../../Components/Footer/FooterTwo';

export const TermsOfUse = () => {
  return (
    <>
      <Navbar />
      <div className='terms_of_service'>
        <div className="tos-intro">
          <h2>Terms of Service (TOS)</h2>
          <p>These Terms of Service ("Terms") govern your use of the Instantly Insured website operated by us. Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.</p>
        </div>

        <div className="tos-content">
          <h3>Content</h3>
          <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness.</p>

          <h3>Links To Other Web Sites</h3>
          <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by Instantly Insured. Instantly Insured has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that Instantly Insured shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>

          <h3>Termination</h3>
          <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>

          <h3>Governing Law</h3>
          <p>These Terms shall be governed and construed in accordance with the laws of [your jurisdiction], without regard to its conflict of law provisions.</p>

          <h3>Changes</h3>
          <p> We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least [days' notice] prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
          
        </div>
      </div>

      <FooterTwo />
    </>
  )
}
