import React, { useEffect, useRef, useState } from 'react';
import { Navbar } from '../../Components/Navbar/Navbar';
import { FooterTwo } from '../../Components/Footer/FooterTwo';
import { motion, useAnimation, useInView } from 'framer-motion';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import './FAQs.css';

const initialVaariants = {
  hidden: {
    x: 100,
    opacity: 0
  },
  visible: {
    x: 0,
    opacity: 1
  }
}

const FAQ = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq">
      <div className="faq-question" onClick={toggleAnswer}>
        <span className='ques-span'> {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}{question}</span>
        <span className={`faq-icon ${isOpen ? 'open' : 'closed'}`}><span className={`faq-icon ${isOpen ? 'open' : 'closed'}`}></span></span>
      </div>
      {/* {isOpen && <div className="faq-answer">{answer}</div>} */}
      <div className={`faq-answer ${isOpen ? 'active' : ''}`}>{answer}</div>
    </div>
  );
};

export const FAQs = () => {

  useEffect(() => {
    document.title = 'FAQs -Instantly Insured';
  }, []);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  return (
    <>
      <Navbar />
      <div className="FAQs" ref={ref}>
        <h2>FAQs</h2>

        <motion.div className="FAQdiv"
          variants={initialVaariants}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 0.3, duration: 1.2 }}
        >
          <FAQ
            question="Why should I choose Instantly Insured as my insurance broker?"
            answer="Instantly Insured is a trusted insurance brokerage firm with a strong commitment to personalized service and customer satisfaction. We offer access to top-rated insurance carriers, tailor-made coverage options, and dedicated support from our experienced agents. Expect the Unexpected, and let us be your trusted insurance advisors."
          />
        </motion.div>

        <motion.div className="FAQdiv"
          variants={initialVaariants}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <FAQ
            question="What types of insurance services do you offer?"
            answer="We provide a comprehensive range of insurance services, including Auto Insurance, Home Insurance, Medical Insurance, Business Insurance, and Specialty Insurance for unique assets. Our coverage options cater to the diverse needs of New York residents and businesses."
          />
        </motion.div>

        <motion.div className="FAQdiv"
          variants={initialVaariants}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 0.7, duration: 1 }}
        >
          <FAQ
            question="How do I get a quote for insurance coverage?"
            answer='Getting a quote is easy! Simply fill out the "Get a Quote" form on our website with your details and coverage preferences. One of our agents will promptly reach out to you with a customized quote tailored to your needs.'
          />
        </motion.div>

        <motion.div className="FAQdiv"
          variants={initialVaariants}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 0.7, duration: 1 }}
        >
          <FAQ
            question="Is requesting a quote from Instantly Insured free?"
            answer="Absolutely! Requesting a quote from Instantly Insured is completely free, and there are no obligations to purchase a policy. We want you to make informed decisions without any pressure."
          />
        </motion.div>

        <motion.div className="FAQdiv"
          variants={initialVaariants}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 0.9, duration: 1 }}
        >
          <FAQ
            question="Can I bundle multiple insurance policies with Instantly Insured?"
            answer="Yes, you can! Bundling multiple insurance policies, such as auto and home insurance, can often lead to cost savings and simplified coverage management. Our agents can help you explore bundle options that suit your needs."
          />
        </motion.div>

        <motion.div className="FAQdiv"
          variants={initialVaariants}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 1.1, duration: 1 }}
        >
          <FAQ
            question="How long does it take to receive a quote after submitting the form?"
            answer="Our agents strive to respond promptly to all quote requests. You can expect to receive your customized quote within 1-2 business days after submitting the form."
          />
        </motion.div>

        <motion.div className="FAQdiv"
          variants={initialVaariants}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 1.3, duration: 1 }}
        >
          <FAQ
            question="Are my personal details secure when submitting the quote form?"
            answer="Yes, your privacy and data security are of utmost importance to us. We follow strict data protection protocols to ensure your personal information is safe and only used for the purpose of providing insurance quotes and services."
          />
        </motion.div>

        <motion.div className="FAQdiv"
          variants={initialVaariants}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 1.5, duration: 1 }}
        >
          <FAQ
            question="Can I review my coverage options before making a decision?"
            answer="Absolutely! Once you receive your quote, our agents will explain your coverage options in detail and answer any questions you may have. We encourage you to review your options carefully before making a decision."
          />
        </motion.div>

        <motion.div className="FAQdiv"
          variants={initialVaariants}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 1.7, duration: 1 }}
        >
          <FAQ
            question="What if I need to make changes to my policy after purchasing it?"
            answer="We understand that life is ever-changing, and your insurance needs may evolve. Our agents are here to assist you with policy adjustments, additions, or any other changes you require throughout your coverage period."
          />
        </motion.div>

        <motion.div className="FAQdiv"
        variants={initialVaariants}
        initial="hidden"
        animate={mainControls}
        transition={{ delay: 1.9, duration: 1.2 }}
        >
          <FAQ
            question="How do I file a claim if I need to use my insurance coverage?"
            answer="In the event of a claim, simply reach out to our agents, and they will guide you through the claims process. We prioritize quick and efficient claims processing to provide timely support during challenging times."
          />
        </motion.div>
      </div >

      <div className="cpEndNote">
        <h3>At Instantly Insured, we believe in transparent communication and addressing your concerns. If you have any other questions or need further information, feel free to contact us. Expect the Unexpected, and trust in our commitment to your insurance needs.</h3>
      </div>

      <FooterTwo />
    </>
  )
}
