import React from 'react'
import { Navbar } from '../../Components/Navbar/Navbar'
import { FooterTwo } from '../../Components/Footer/FooterTwo'
import './Sitemap.css';
import { Link } from 'react-router-dom';

export const Sitemap = () => {
  return (
    <>
      <Navbar />
      <div className="sitemap">
        <div className="sitemapTitle">
          <h1>Site Map</h1>
        </div>

        <div className="siteContent">
          <div className="scItem">
            <h2>MAIN</h2>

            <h3>Home</h3>
            <Link to="/" className='siteLink'>Instantly Insured: Your Shield Against the Unexpected</Link>
          </div>
        </div>

        <div className="siteContent">
          <div className="scItem">
            <h2>INSURANCE</h2>
            <div className="scIsubItem">
              <div className="subItemContent">
                <h3>Insurance Services</h3>
                <Link to="/insurance_services" className='siteLink'>Types of Insurance services provided by Instantly Insured</Link>
              </div>
              <div className="subItemContent">
                <h3>Life</h3>
                <Link to="/insurance_services/life" className='siteLink'>Life Insurance</Link>
              </div>

              <div className="subItemContent">
                <h3>Auto</h3>
                <Link to="/insurance_services/auto" className='siteLink'>Auto Insurance</Link>
              </div>

              <div className="subItemContent">
                <h3>Home</h3>
                <Link to="/insurance_services/home" className='siteLink'>Home Insurance</Link>
              </div>

              <div className="subItemContent">
                <h3>Medical</h3>
                <Link to="/insurance_services/medical" className='siteLink'>Medical Insurance</Link>
              </div>

              <div className="subItemContent">
                <h3>Business</h3>
                <Link to="/insurance_services/business" className='siteLink'>Business Insurance</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="siteContent">
          <div className="scItem">
            <h2>CARRIERS</h2>

            <h3>Insurance Carriers</h3>
            <Link to="/insurance_carriers" className='siteLink'>A wide network of top-rated insurance carriers</Link>
          </div>
        </div>

        <div className="siteContent">
          <div className="scItem">
            <h2>QUOTES</h2>
            <Link to="/get_a_quote" className='siteLink'>Get a Quote</Link>
          </div>
        </div>

        <div className="siteContent">
          <div className="scItem">
            <h2>CUSTOMER CARE</h2>
            <Link to="/contact_us" className='siteLink'>Contact Us</Link>
          </div>
        </div>

        <div className="siteContent">
          <div className="scItem">
            <h2>FAQS</h2>
            <Link to="/FAQs" className='siteLink'>Frequently Asked Questions</Link>
          </div>
        </div>

        <div className="siteContent">
          <div className="scItem">
            <h2>Sitemap</h2>
            <Link to="/sitemap" className='siteLink'>Sitemap</Link>
          </div>
        </div>
      </div>
      <FooterTwo />
    </>
  )
}
