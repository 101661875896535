import React from 'react';
import insuranceServiceBG from '../../../assets/insuranceServices.jpg';
import icBg from '../../../assets/insuranceCarriersBg.jpg';
import './InsuranceHero.css';

export const InsuranceHero = ({ currentPage }) => {
  return (
    <div className='insuranceHero'>
      {currentPage === "Insurance Services" && <img src={insuranceServiceBG} alt="Insurance Services" className='insuranceHeroImg'/>}
      {currentPage === "Insurance Carriers" && <img src={icBg} alt="Insurance Carriers" className='insuranceHeroImg'/>}
      <div className="insuranceHeroDesc">
        {currentPage === "Insurance Services" && <h1 className="ihTtitle">Insurance Services</h1>}
        {currentPage === "Insurance Carriers" && <h1 className="ihTtitle">Insurance Carriers</h1>}

        {currentPage === "Insurance Services" && <h3>At Instantly Insured, we offer a comprehensive range of insurance services tailored to meet the diverse needs of New York residents. Our expert team is dedicated to providing you with the perfect coverage options, ensuring that you and your loved ones are protected against life's unpredictable events. Explore our array of insurance services designed to offer peace of mind and financial security</h3>}
        {currentPage === "Insurance Carriers" && <h3>At Instantly Insured, we pride ourselves on offering access to a wide network of top-rated insurance carriers. These esteemed partnerships enable us to provide our clients with a diverse range of reliable and high-quality insurance products. We have carefully selected these carriers based on their track record of exceptional service, financial stability, and commitment to customer satisfaction.</h3>}
      </div>
    </div>
  )
}
