import React, { useEffect } from 'react'
import { Navbar } from '../../Components/Navbar/Navbar'
import { Hero } from '../../Components/Hero/Hero'
import { About } from '../../Components/About/About'
import { Testimonial } from '../../Components/Testimonial/Testimonial'
import { InsuranceServices } from '../../Components/InsuranceServices/InsuranceServices'
import { WhyChooseUs } from '../../Components/WhyChooseUs/WhyChooseUs'
// import { Contact } from '../../Components/Contact/Contact'
// import { Trust } from '../../Components/Trust/Trust'
// import { Footer } from '../../Components/Footer/Footer'
import { TrustTwo } from '../../Components/Trust/TrustTwo'
import { FooterTwo } from '../../Components/Footer/FooterTwo'
// import { TestimonialV2 } from '../../Components/Testimonial/TestimonialV2'

export const Home = () => {

  useEffect(() => {
		document.title = 'Instantly Insured';
	  }, []);

  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      {/* <TestimonialV2 /> */}
      <Testimonial />
      <InsuranceServices currentPage="Home" />
      <WhyChooseUs currentPage="Home"/>
      {/* <Contact currentPage="Home"/> */}
      {/* <Trust /> */}
      <TrustTwo />
      {/* <Footer /> */}
      <FooterTwo />
    </div>
  )
}
