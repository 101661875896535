import React, { useEffect, useRef } from 'react';
import liberty from '../../assets/logos/liberty.png';
import hartford from '../../assets/logos/hartford.png';
import sefaco from '../../assets/logos/safeco.png';
import travelers from '../../assets/logos/travelers.png';
import chubb from '../../assets/chubb.png';
import { motion, useAnimation, useInView } from 'framer-motion';
import './About.css';

const initialVaariants = {
  hidden: {
    x: '-100vw',
    opacity: 0
  },
  visible: {
    x: 0,
    opacity: 1
  }
}


export const About = () => {

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true} );
  const mainControls = useAnimation();

  useEffect(() => {
    if(isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls])

  return (
    <div className='about'>
      <div className="aboutItem">
        <h2 className="aboutTitle" ref={ref}>About Brokerage</h2>
        <motion.h3 className="aboutContent"
          variants={initialVaariants}
          initial="hidden"
          animate={mainControls}
          transition={{ delay: 0.5, duration: 0.5 }}
        >"At Instantly Insured, we are more than just insurance brokers; we are your dedicated partners in safeguarding what matters most. With a deep-rooted commitment to New York communities, our team of seasoned experts brings decades of experience to the table, ensuring you receive personalized insurance solutions tailored to your unique needs. Our ethos revolves around being there for you when life throws unexpected challenges your way. Expect the Unexpected – with Instantly Insured, you're never alone on your journey to financial security and peace of mind."</motion.h3>
      </div>
      {/* <div className="aboutTrustedLogos">
        <img src={liberty} alt="Liberty" className="trustedLogos" />
        <img src={hartford} alt="Hart ford" className="trustedLogos" />
        <img src={sefaco} alt="Trusted" className="trustedLogos" />
        <img src={travelers} alt="Trusted" className="trustedLogos" />
        <img src={chubb} alt="Trusted" className="trustedLogos" />
      </div> */}
    </div>
  )
}
