import {
  BrowserRouter as Router, Routes, Route,
} from 'react-router-dom';
import { Home } from "./Pages/Home/Home";
// import { AboutUs } from './Pages/AboutUs/AboutUs';
import { ScrollToTop } from './Components/ScrollToTop';
import { InsuranceTypes } from './Pages/InsuranceTypes/InsuranceTypes';
import { SingleInsuranceService } from './Components/InsuranceTypes/SingleInsuranceService/SingleInsuranceService';
import auto from './assets/1.jpg';
import home from './assets/2.jpg';
import medical from './assets/3.jpg';
import business from  './assets/4.jpg';
import life from './assets/life.jpg';
import { ContactPage } from './Pages/Contact/ContactPage';
import { GetAAquote } from './Pages/GetAQuote/GetAAquote';
import { InsuranceCarriers } from './Pages/InsuranceCarriers/InsuranceCarriers';
import { Search } from './Components/Search/Search';
import { FAQs } from './Pages/FAQs/FAQs';
import { TermsOfUse } from './Pages/TermsofUse/TermsOfUse';
import { Privacy } from './Pages/Privacy/Privacy';
import { Sitemap } from './Pages/Sitemap/Sitemap';
import Thanks from './Pages/Thanks/Thanks';
// import { Blogs } from './Pages/Blogs/Blogs';


function App() {
  
  const insuranceServiceContent = {
    auto: {
      title: "Auto Insurance",
      description: "Drive confidently knowing that you and your vehicle are protected with our auto insurance policies. We offer coverage for cars, motorcycles, and other vehicles, safeguarding you from accidents, theft, and liabilities. Whether you're commuting or exploring New York's scenic routes, our auto insurance keeps you covered.",
    },
    home: {
      title: "Home Insurance",
      description: "Your home is a significant investment, and our home insurance plans are tailored to protect it from unforeseen events. With coverage for property damage, theft, and personal belongings, you can rest easy knowing your sanctuary is secure.",
    },
    medical: {
      title: "Medical Insurance",
      description: "Your health is invaluable, and our medical insurance solutions ensure you have access to quality healthcare when you need it most. From doctor's visits to hospitalizations, our comprehensive medical coverage supports your well-being.",
    },
    business: {
      title: "Business Insurance",
      description: "As a business owner in New York, safeguarding your enterprise is crucial. Our business insurance coverage protects your company from various risks, including liability claims, property damage, and business interruption.",
    },
    life: {
      title: "Life Insurance",
      description: "At Instantly Insured, we understand the importance of securing your family's future, no matter what lies ahead. Our Life Insurance service offers comprehensive coverage options designed to provide financial protection and peace of mind. Whether you're looking to safeguard your loved ones' well-being or leave a legacy, our tailored policies cater to your unique needs.",
    }
    // Add more services as needed
  };

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/about_us" element={<AboutUs />} /> */}
          <Route path="/insurance_services" element={<InsuranceTypes />} />
            <Route path="/insurance_services/auto" element={<SingleInsuranceService sisImage={auto} insuranceTitle={insuranceServiceContent.auto.title} insuranceDesc={insuranceServiceContent.auto.description} />} />
            <Route path="/insurance_services/home" element={<SingleInsuranceService sisImage={home} insuranceTitle={insuranceServiceContent.home.title} insuranceDesc={insuranceServiceContent.home.description}/>} />
            <Route path="/insurance_services/medical" element={<SingleInsuranceService sisImage={medical} insuranceTitle={insuranceServiceContent.medical.title} insuranceDesc={insuranceServiceContent.medical.description}/>} />
            <Route path="/insurance_services/business" element={<SingleInsuranceService sisImage={business} insuranceTitle={insuranceServiceContent.business.title} insuranceDesc={insuranceServiceContent.business.description}/>} />
            <Route path="/insurance_services/life" element={<SingleInsuranceService sisImage={life} insuranceTitle={insuranceServiceContent.life.title} insuranceDesc={insuranceServiceContent.life.description} currentPage="life"/>} />
          {/* <Route path="/insurance_carriers" element={<InsuranceCarriers />}/> */}
          <Route path="/contact_us" element={<ContactPage />}/>
          <Route path="/FAQs" element={<FAQs />}/>
          {/* <Route path="/blogs" element={<Blogs />}/> */}
          <Route path="/get_a_quote" element={<GetAAquote />}/>
          <Route path="/search" element={<Search />}/>
          <Route path="/sitemap" element={<Sitemap />}/>
          <Route path="/terms-of-service" element={<TermsOfUse />}/>
          <Route path="/privacy-policy" element={<Privacy />}/>
          <Route path="/thanks" element={<Thanks />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
